import React, { useEffect, useState } from "react";
import styled from "styled-components";
import useMediaQuery from "../../shared/hooks/useMediaQuery";
import { AnimatePresence, motion } from "framer-motion";
import { theme } from "./../../styles/theme";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

import logoru from "../../shared/png/лого майпост русс.png";
import logoEng from "../../shared/png/final better.png";
import logoKg from "../../shared/png/лого майпост кырг.png";
interface MenuItemType {
  id: number;
  name: string;
  link?: string;
  items?: {
    id: number;
    title: string;
    link: string;
  }[];
}

const variants = {
  open: { opacity: 1, x: 0 },
  closed: { x: "-100%" },
};

const navbarItems: MenuItemType[] = [
  {
    id: 1,
    name: "О нас",
    link:'/aboutUs'
  },
  {
    id: 2,
    name: "Контакты",
  },
];

const Header = () => {
  const lg = useMediaQuery("lg");
  const isMobile = useMediaQuery("sm");
  const navigate = useNavigate();
  const [isMenuVisible, setMenuVisible] = useState<boolean>(false);

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = [logoru, logoEng, logoKg];
  const intervalDuration = 3000;
  const scrollToElement = (elementId:any) => {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth", 
      });
    }
  };
  
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex: any) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, intervalDuration);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const toggleMenu = () => {
    setMenuVisible(!isMenuVisible);
  };

  const handleMenuItemClick = (link: string | undefined) => {
    if (link) {
      navigate(link);
      setMenuVisible(false); // Close the menu after clicking
    }
  };

  return (
    <Wrapper>
      <Container>
        <HeaderContent>
          {lg && (
            <BurgerMenu onClick={toggleMenu} isMenuVisible={isMenuVisible}>
              <span />
            </BurgerMenu>
          )}
          {!lg && (
            <img
              src={images[currentImageIndex]}
              alt="my post logo"
              width={130}
              style={{ borderRadius: "20px",cursor:'pointer' }}
              onClick={()=>navigate('/')}
            />
          )}
          <CityLink>
            <CityBlock>
              {navbarItems.map((item, index) => (
                <motion.p
                  initial={{ opacity: 0, y: 30 }}
                  animate={{
                    opacity: 1,
                    y: 0,
                  }}
                  transition={{
                    duration: 0.5,
                    delay: index * 0.3,
                  }}
                  key={index}
                  onClick={() => handleMenuItemClick(item.link)}
                  style={{ cursor: item.link ? "pointer" : "default" }}
                >
                  {item.name}
                </motion.p>
              ))}
              <a href="#ourAd"> Наши преимущства</a>
            </CityBlock>
          </CityLink>
          <Buttons>
            <Button
              // @ts-ignore
              sx={
                lg && {
                  minWidth: 30,
                  fontSize: "8px",
                }
              }
              onClick={() => navigate("/clientAuth")}
            >
              <p>
                Курьеры, почтальоны, <strong> ЖМИТЕ сюда</strong>
              </p>
            </Button>{" "}
            <Button
              // @ts-ignore
              sx={
                lg && {
                  minWidth: 30,
                  fontSize: "8px",
                }
              }
              onClick={() => navigate("/partnership")}
            >
              <p>
                По вопросам сотрудничества
                <strong> ЖМИТЕ сюда</strong>
              </p>
            </Button>
          </Buttons>
          <AnimatePresence>
            {lg && (
              <MobileMenu
                animate={isMenuVisible ? "open" : "closed"}
                variants={variants}
              >
                <MobileMenuList>
                  {navbarItems.map((item: MenuItemType) => (
                    <MobileMenuItem
                      key={item.id}
                      onClick={() => handleMenuItemClick(item.link)}
                    >
                      {item.name}
                    </MobileMenuItem>
                  ))}
                </MobileMenuList>
              </MobileMenu>
            )}
          </AnimatePresence>
        </HeaderContent>
      </Container>
    </Wrapper>
  );
};

const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  ${theme.mqMax("sm")} {
    margin-left: -130px;
    align-items: center;
    flex-direction: row;
  }
`;
const Wrapper = styled.header`
  width: 100%;
  z-index: 2;
  padding: 15px 0;
  border-bottom: 2px black solid;
  transition: transform 0.3s ease-in-out;
  font-family: "Plus Jakarta Sans", sans-serif;
  ${theme.mqMax("lg")} {
    height: 80px;
    padding: 12px 0;
  }
`;
const Container = styled.div`
  max-width: ${theme.containers.main};
  margin: 0 auto;
  width: 100%;
  z-index: 100;
  ${theme.mqMax("xl")} {
    padding: 0 25px 0 30px;
  }
`;

const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Montserrat", sans-serif;
  color: white;
  ${theme.mqMax("lg")} {
    display: flex;
  }
`;

const CityLink = styled.div`
  margin-left: 240px;
  /* flex-grow: 1; */
`;
const CityBlock = styled.div`
  display: flex;
  align-items: center;
  column-gap: 20px;
  color: black;
  font-weight: 700;
  font-family: "Montserrat";
  justify-content: center;
  /* margin-left: 279px; */
  ${theme.mqMax("md")} {
    justify-content: center;
  }
  ${theme.mqMax("lg")} {
    display: none;
  }

  p {
    cursor: pointer;
    transition-duration: 0.3s;
    &:hover {
      color: #1976d2;
    }
  }
`;

const LinkItem = styled.h4`
  font-family: "Plus Jakarta Sans", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 16px;
  color: ${theme.colors.dark};
  white-space: nowrap;
  padding: 10px;
  /* 1200 */
  ${theme.mqMax("xl")} {
    font-size: 14px;
  }
`;

const BurgerMenu = styled.div<{ isMenuVisible: boolean }>`
  position: relative;
  margin-top: 10px;
  cursor: pointer;
  height: 16px;
  min-width: 23px;
  z-index: 99999;
  display: flex;
  align-items: center;

  span {
    display: ${(p) => (p.isMenuVisible ? "none" : "block")};
    height: 2px;
    width: 100%;
    background-color: ${theme.colors.dark};
  }
  &:before,
  &:after {
    content: "";
    position: absolute;
    left: 0;
    width: 23px;
    height: 2px;
    background-color: ${theme.colors.dark};
    transition: transform 0.2s ease;
  }
  &:before {
    transform: ${(p) => (p.isMenuVisible ? "rotate(45deg)" : "rotate(0)")};
    top: 0;
  }
  &:after {
    transform: ${(p) => (p.isMenuVisible ? "rotate(-45deg)" : "rotate(0)")};
    bottom: ${(p) => (!p.isMenuVisible ? 0 : "initial")};
    top: ${(p) => (p.isMenuVisible ? 0 : "initial")};
  }
`;

const MobileMenu = styled(motion.div)`
  position: absolute;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #1976d2;
  display: flex;
  flex-direction: column;
  gap: 10px;
  text-align: center;
  padding: 100px 0;
`;
const MobileMenuList = styled.ul`
  display: flex;
  flex-direction: column;
  color: white;
`;
const MobileMenuItem = styled(LinkItem)`
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 16px;
  text-transform: uppercase;
  color: ${theme.colors.white};
`;

export default Header;
