import "../src/styles/globals.css";
import createTheme from "@mui/material/styles/createTheme";
import { ThemeProvider } from "@emotion/react";
import { AppRouter } from "./app/provide/router/ui/Router";

function App() {
  const theme = createTheme({
    typography: {
      fontFamily: `"Montserrat", sans-serif`,
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <AppRouter />
    </ThemeProvider>
  );
}

export default App;
