import React from "react";
import { theme } from "src/styles/theme";
import { AboutUs } from "src/widgets/AboutUsComponent/AboutUs";
import styled from "styled-components";

export const AboutUsPage = () => {
  return (
    <div>

      <Container>
        <h1>О нас</h1>
        <AboutUs />
      </Container>
    </div>
  );
};

const { containers, mqMax } = theme;

const Container = styled.div`
  padding: 50px 200px;
  max-width: ${containers.main};
  margin: 0 auto;
  text-align: center;
  
  ${mqMax("xxl")} {
    padding: 0px 30px;
  }
`;
