import React, { useState } from "react";
import { TextField, Button, Grid, Alert, Snackbar } from "@mui/material";
import { theme } from "src/styles/theme";
import { styled } from "styled-components";
import { api } from "src/shared/configs/axios/axiosConfig";
import { notification } from "antd";
import { useNavigate } from "react-router-dom";

export const ClientAuthComponent = () => {
  const [formData, setFormData] = useState({
    fullName: "",
    phoneNumber: "+996",
    telegram:'@'
  });
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [open, setOpen] = React.useState(false);

  const handleFormSubmit = async (e: any) => {
    e.preventDefault();
    try {
      const response = await api.post("/courier", formData);
      if (response.status === 200) {
        console.log("Message sent successfully!");
      } else {
        console.error("Message sending failed");
      }
      notification.success({
        message: "Ваши данные были успешно доставлены",
      });
      setFormSubmitted(true);
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const navigate = useNavigate()

  return (
    <Container>
      <Button onClick={()=>navigate('/')}>На главную</Button>
      <form onSubmit={handleFormSubmit}>
        <h3>Стань курьером</h3>
        <h5>Введите свои данные</h5>
        <br />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="ФИО"
              variant="outlined"
              value={formData.fullName}
              name="fullName"
              required
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              inputProps={{
                maxLength: "13",
              }}
              fullWidth
              name="phoneNumber"
              label="Телефон"
              variant="outlined"
              value={formData.phoneNumber}
              onChange={handleInputChange}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              name="telegram"
              label="Никнейм в телеграме(необязятельно)"
              variant="outlined"
              value={formData.telegram}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Button fullWidth variant="contained" color="primary" type="submit">
              Зарегистрироваться
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};
const { containers, mqMax } = theme;

const Container = styled.div`
  padding: 50px 0;
  max-width: ${containers.main};
  margin: 0 auto;
  ${mqMax("xxl")} {
    padding: 30px 20px;
  }
  form {
    margin: 0 auto;
    max-width: 400px;
    h3,
    h5 {
      display: flex;
    }
  }
`;
