import React, { useState } from "react";
import {
  Button,
  Stack,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { theme } from "../../styles/theme";
import { styled } from "styled-components";
import { useNavigate } from "react-router-dom";
import { MapComponent } from "../../shared/ui/Mao/Map";
import { api } from "src/shared/configs/axios/axiosConfig";
import { notification } from "antd";
import { Sender } from "src/widgets/SenderModal/Sender";

export const Send = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    phoneNumber: "+996",
    message: "",
    region: "",
    address: "",
    sender: "",
  });

  const handleFormSubmit = async (e: any) => {
    e.preventDefault();
    try {
      const response = await api.post("/messages", formData);
      if (response.status === 200) {
        console.log("Message sent successfully!");
      } else {
        console.error("Message sending failed");
      }
      notification.success({
        message: "Ваше сообщение было успешно доставлено",
      });
      navigate("/");
    } catch (error) {
      console.error("Error sending message:", error);
      console.log(formData);
    }
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const isFormValid = () => {
    return (
      formData.name.trim() !== "" &&
      formData.phoneNumber.trim() !== "" &&
      formData.message.trim() !== "" &&
      formData.address.trim() !== ""
    );
  };

  const handleRegionChange = (e: any) => {
    setFormData({ ...formData, region: e.target.value });
  };

  return (
    <div>
      <Container>
        <Stack
          spacing={{ xs: 1, sm: 2 }}
          flexWrap="wrap"
          justifyContent="spaceBetween"
        >
          <TopBlock>
            <Button onClick={() => navigate("/")} variant="outlined">
              Назад
            </Button>
            <br />
            <h1>ПОЛУЧАТЕЛЬ</h1>
          </TopBlock>
        </Stack>
        <form onSubmit={handleFormSubmit}>
          <TextField
            label="Кому"
            variant="outlined"
            fullWidth
            margin="normal"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            required
          />
          <FormControl fullWidth variant="outlined" margin="normal">
            <InputLabel>Наcеленный пункт</InputLabel>
            <Select
              label="Наcеленный пункт"
              name="region"
              value={formData.region}
              onChange={handleRegionChange}
              required
            >
                <MenuItem value="chiu">Чуй</MenuItem>
              <MenuItem value="issyk">Иссык куль</MenuItem>
              <MenuItem value="batken">Баткен</MenuItem>
              <MenuItem value="naryn">Нарын</MenuItem>
              <MenuItem value="djalal">Джалал-абад</MenuItem>
              <MenuItem value="talas">Талас</MenuItem>
              <MenuItem value="osh">Ош</MenuItem>
            </Select>
          </FormControl>
          <TextField
            placeholder="Бишкек(Аламединский район), 3-92-21"
            label="Адрес"
            variant="outlined"
            fullWidth
            margin="normal"
            name="address"
            value={formData.address}
            onChange={handleInputChange}
            required
          />
          <TextField
            inputProps={{
              maxLength: "13",
            }}
            label="Телефон"
            variant="outlined"
            fullWidth
            margin="normal"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleInputChange}
          />
          <TextField
            label="Дополнительная информация"
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            margin="normal"
            name="message"
            value={formData.message}
            onChange={handleInputChange}
          />
          <Sender />
          <MapComponent />
          <br />
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={!isFormValid()}
          >
            Отправить
          </Button>
        </form>
      </Container>
    </div>
  );
};

const { containers, mqMax } = theme;

const TopBlock = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`;
const Container = styled.div`
  padding: 50px 200px;
  max-width: ${containers.main};
  margin: 0 auto;
  ${mqMax("xxl")} {
    padding: 30px 20px;
  }
`;

const AddressComponent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
