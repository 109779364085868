import React from 'react';
import { Hero } from 'src/widgets/Hero';
import { OurAdvantages } from 'src/widgets/OurAdvantages/OurAdvantages';

export const MainPage = () => {
    return (
        <div>
           <Hero/> 
           <OurAdvantages/>
        </div>
    );
};
