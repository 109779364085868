import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import { motion } from "framer-motion";
import Button from "@mui/material/Button";
import HelpIcon from "@mui/icons-material/Help";
import MailIcon from '@mui/icons-material/Mail';
import Stack from "@mui/material/Stack";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import { theme } from "../styles/theme";
import useMediaQuery from "../shared/hooks/useMediaQuery";
import { useNavigate } from "react-router-dom";
import logoru from '../shared/png/лого майпост русс.png'
import logoEng from '../shared/png/final better.png'
import logoKg from '../shared/png/лого майпост кырг.png'
import { MapComponent } from "src/shared/ui/Mao/Map";
export const Hero = () => {
  const isMobile = useMediaQuery("sm");
  const navigate = useNavigate();
  
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = [logoru, logoEng, logoKg];
  const intervalDuration = 3000; 

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex:any) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, intervalDuration);

    return () => {
      clearInterval(interval);
    };
  }, []);
  return (
    <Container>
      <Main
        initial={{ opacity: 0, y: -10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.9 }}
      >
        <img src={images[currentImageIndex]} alt="my post logo" width={250}  />
      </Main>
      <Buttons>
        <Stack direction={isMobile ? "column" : "row"} spacing={2}>
          {/* <Button
            variant="outlined"
            startIcon={<HelpIcon />}
            onClick={() => navigate("/support")}
          >
            Обратиться за помощью
          </Button> */}
          <Button
            variant="contained"
            endIcon={<MailIcon />}
            onClick={() => navigate("/send")}
          >
            Отправить почту
          </Button>
          <Button
            variant="contained"
            endIcon={<LocalShippingIcon />}
            onClick={() => navigate("/sendGoods")}
          >
            Отправить груз
          </Button>
        </Stack>
      </Buttons>
      <br />
      <MapComponent/>
      
    </Container>
  );
};

const { containers, mqMax } = theme;
const Container = styled.div`
  max-width: ${containers.main};
  margin: 0 auto;
  text-align: center;
  padding-bottom: 100px;
  ${mqMax("xxl")} {
    padding: 0 20px;
  }
`;
const Main = styled(motion.div)`
  margin-top: 100px;
  font-weight: 800;
  font-size: 40px;
  span {
    cursor: pointer;
  }
  img{
    transition-duration: 3ms;
  } img:after{
    
  }
`;

const Buttons = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: center;
`;