import { YMaps, Map, Placemark, ZoomControl } from "@pbe/react-yandex-maps";
import React, { useEffect, useState } from "react";
import person from "../../png/person-boy-svgrepo-com.svg";
import car from "../../png/smallCar.png";
import { security } from "src/shared/consts/cities";
export const MapComponent = () => {
  const [placemarkCoordinates, setPlacemarkCoordinates] = useState(null);
  const [userLocation, setUserLocation]: any = useState(null);
  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        setUserLocation([latitude, longitude]);
        console.log({ latitude, longitude });
      },
      (er) => {
        console.log("err", er);
      }
    );
  }, []);

  const handleMapClick = (e: any) => {
    const coordinates = e.get("coords");
    setPlacemarkCoordinates(coordinates);
  };

  const postArray = [
    {
      name: "Почтовое отделение 28",
      geo: [42.82769, 74.615967],
    },
    {
      name: "Почтовое отделение 75",
      geo: [42.836476, 74.612315],
    },
  ];
  //

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <YMaps>
        <h3>Наши пункты</h3>
        <br />
        <Map
          defaultState={{
            center: userLocation || [42.82769, 74.615967],
            zoom: 7,
          }}
          width="89%"
          height={300}
          onClick={handleMapClick}
        >
          <ZoomControl options={{ size: "small" }} />{" "}
          {/* Add the ZoomControl here */}
          <Placemark
            geometry={userLocation}
            options={{
              iconImageHref: person,
              iconImageSize: [32, 32],
              iconLayout: "default#image",
            }}
          />
          {security.map((item, index) => (
            <Placemark
              options={{
                iconImageHref: car,
                iconImageSize: [44, 25],
                iconLayout: "default#image",
              }}
              key={index}
              geometry={item.geo}
              onClick={() => alert(item.name)}
            />
          ))}
          {/* ДЛЯ ПОЧТ */}
          {/* {postArray.map((item, index) => (
            <Placemark
              key={index}
              geometry={item.geo}
              options={{
                iconImageHref: icon,
                iconImageSize: [32, 32],
                iconLayout: "default#image",
              }}
              properties={{
                hintContent: "alskdkjkasd",
                baloonContent: `'<div id="driver-2" class="driver-card">asdaslkdmkasmld</div>`,
              }}
              onClick={() => alert(item.name)}
            />
          ))} */}
        </Map>
      </YMaps>
    </div>
  );
};
