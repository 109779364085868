import { RouteProps } from "react-router";
import { Hero } from "../../../widgets/Hero";
import { Send } from "../../../pages/SendMail";
import { SendGoods } from "../../../pages/SendGoods";
import { Support } from "src/pages/Support";
import { ClientAuth } from "src/pages/ClientAuth/ClientAuth";
import { PartnershipPage } from "src/pages/Partnership/PartnershipPage";
import { MainPage } from "src/pages/MainPage/MainPage";
import { AboutUsPage } from "src/pages/AboutUsPage/AboutUsPage";

export enum AppRoutes {
  MAIN = "MAIN",
  SEND = "SEND",
  SENDGOODS = "SENDGOODS",
  SUPPORT = "SUPPORT",
  CLIENT_AUTH = "CLIENT_AUTH",
  PARTNESHIP = "PARTNERSHIP",
  ABOUT_US = "ABOUT_US",
}

export const RoutePath: Record<AppRoutes, string> = {
  [AppRoutes.MAIN]: "/",
  [AppRoutes.SEND]: "/send",
  [AppRoutes.SENDGOODS]: "/sendGoods",
  [AppRoutes.SUPPORT]: "/support",
  [AppRoutes.CLIENT_AUTH]: "/clientAuth",
  [AppRoutes.PARTNESHIP]: "/partnership",
  [AppRoutes.ABOUT_US]: "/aboutUs",
};

export const routeConfigPrivate: Record<AppRoutes, RouteProps> = {
  [AppRoutes.MAIN]: {
    path: RoutePath.MAIN,
    element: <MainPage />,
  },
  [AppRoutes.SEND]: {
    path: RoutePath.SEND,
    element: <Send />,
  },
  [AppRoutes.SENDGOODS]: {
    path: RoutePath.SENDGOODS,
    element: <SendGoods />,
  },
  [AppRoutes.SUPPORT]: {
    path: RoutePath.SUPPORT,
    element: <Support />,
  },
  [AppRoutes.CLIENT_AUTH]: {
    path: RoutePath.CLIENT_AUTH,
    element: <ClientAuth />,
  },
  [AppRoutes.PARTNESHIP]: {
    path: RoutePath.PARTNERSHIP,
    element: <PartnershipPage />,
  },
  [AppRoutes.ABOUT_US]: {
    path: RoutePath.ABOUT_US,
    element: <AboutUsPage />,
  },
};
