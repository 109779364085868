export const security = [
  {
    name: "Административное здание в г. Бишкек",
    geo: [42.875243, 74.604958],
  },
  {
    name: `Помещение в здании "КП" г.Балыкчи`,
    geo: [42.462882, 76.180716],
  },
  {
    name: `Помещение в здании "КП" г.Кант`,
    geo: [42.889638, 74.84524],
  },
  {
    name: `Помещение в здании "КП" г.Кара-Балта`,
    geo: [42.824638, 73.847257],
  },
  {
    name: `Помещение в здании "КП" г.Каракол`,
    geo: [42.493524, 78.398248],
  },
  {
    name: `Помещение в здании "КП" г.Нарын`,
    geo: [41.428838, 75.999071],
  },
  {
    name: `Помещение в здании "КП" с.Ат-Баши`,
    geo: [41.168662, 75.813139],
  },
  {
    name: `Помещение в здании "КП" с.Баетово `,
    geo: [41.264141, 74.955993],
  },
  {
    name: `Помещение в здании "КП" с.Баетово`,
    geo: [41.264141, 74.955993],
  },
  {
    name: `Помещение в здании "КП" с.Кочкор`,
    geo: [42.219303, 75.765408],
  },
  {
    name: `Помещение в здании "КП" с.Талас`,
    geo: [42.518388, 72.246839],
  },
  {
    name: `Помещение в здании "Телеком" г.Чолпон-Ата`,
    geo: [42.649742, 77.085755],
  },
  {
    name: `помещение в здании КП г.Аксы`,
    geo: [41.493062, 71.757484],
  },
  {
    name: `помещение в здании КП г.Майлуу-Суу `,
    geo: [41.254946, 72.462417],
  },
  {
    name: `помещение в здании КП г.Таш-Кумыр`,
    geo: [41.338887, 72.203524],
  },
  {
    name: `помещение в здании КП г Жалал-Абад`,
    geo: [40.92562618017678, 72.99995933312694],
  },
  {
    name: `помещение в здании КП с.Ала-Бука`,
    geo: [],
  },
];
