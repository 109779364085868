import { Route, Routes } from "react-router-dom";
import { routeConfigPrivate } from './../routerConfig';

export const AppRouter = () => {
  const routes = routeConfigPrivate;
  return (
    <Routes>
      {Object.values(routes).map(({ element, path }, index) => (
        <Route key={index} path={path} element={element} />
      ))}
    </Routes>
  );
};
