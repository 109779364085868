import { Button, Stack, TextField } from "@mui/material";
import { theme } from "../../styles/theme";
import { styled } from "styled-components";
import { useNavigate } from 'react-router-dom';
import { MapComponent } from "../../shared/ui/Mao/Map";

export const SendGoods = () => {
    const navigate= useNavigate()
  return (
    <div>
      <Container>
    <Stack direction="row" spacing={{ xs: 1, sm: 2 }}  flexWrap="wrap" justifyContent="spaceBetween">
    <Button onClick={()=> navigate('/')} variant="outlined">Назад</Button>
        <h4>Отправить груз получателю</h4>
    </Stack>
        <form>
          <TextField
            label="Имя"
            variant="outlined"
            fullWidth
            margin="normal"
          />
          <TextField
            label="Email"
            variant="outlined"
            fullWidth
            margin="normal"
          />
          <TextField
            label="Телефон"
            variant="outlined"
            fullWidth
            margin="normal"
          />
          <TextField
            label="Сообщение"
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            margin="normal"
          />
          <TextField
            label="Адрес"
            variant="outlined"
            fullWidth
            margin="normal"
          />
          <TextField
            label="Город"
            variant="outlined"
            fullWidth
            margin="normal"
          />
          <MapComponent/>
          <br />
          <Button variant="contained" color="primary" type="submit" >
            Отправить
          </Button>
        </form>
      </Container>
    </div>
  );
};

const { containers, mqMax } = theme;

const Container = styled.div`
padding: 50px 200px;
  max-width: ${containers.main};
  margin: 0 auto;
  ${mqMax("xxl")} {
    padding: 30px 20px;
  }
`;
