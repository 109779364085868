import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { styled } from "styled-components";
import { theme } from "src/styles/theme";

export const Support = () => {
  const navigate = useNavigate();
  return (
    <div>
      <Container>
        <Button onClick={() => navigate("/")} variant="outlined">
          Назад
        </Button>

        <h1>Страница поддержки пользователя</h1>
      </Container>
    </div>
  );
};

const { containers, mqMax } = theme;

const Container = styled.div`
    text-align: center;
  padding: 50px 200px;
  max-width: ${containers.main};
  margin: 0 auto;
  ${mqMax("xxl")} {
    padding: 30px 20px;
  }
`;
