import React from "react";
import { theme } from "src/styles/theme";
import styled from "styled-components";

export const AboutUs = () => {
  return (
    <Wrapper id="ourAd">
      <Container>
        <Blocks>
          <Block>
            <div className="">
              <h4>
                МОЯ ПОЧТА это удобный сервис по приему и доставке
                корреспонденции и грузов различных категорий по территории
                Кыргызстана.{" "}
              </h4>
              Как с нами работать?
              <ul>
                <li>• с сайта www.mypost.kg</li>
                <li>• cкачайте приложение mypost.</li>
                <p>
                  А так же мы осуществляем доставку по
                  <br />
                   территории Российской
                  Федерации, 
                  <br />
                  Республики Беларусь, Республики Казахстан,
                  Республики Таджикистан, 
                  
                  Республики Узбекистан, Азербайджанско
                </p>
              </ul>
            </div>
          </Block>
          <Block>
            <h4>
              МОЯ ПОЧТА это работа для курьров и почтальонов. Мы всегда рады
              любому, кто хочет иметь дополнительный заработок и быть полезным в
              доставке и первозке грузов 
              <br />
              <strong>
              Что для этого нужно?
              </strong>
            </h4>
            <ul>
              <li>• Зайти на сайт www.mypost.kg</li>
              <li>• Прийти в любой из наших офисов офисов</li>
              <li>• Просто позвонить к нам</li>
            </ul>
          </Block>
        </Blocks>
      </Container>
    </Wrapper>
  );
};

const { containers, mqMax } = theme;
const Block = styled.div`
  padding: 10px 20px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  box-shadow: 3px 2px 5px gray;
  transition-duration: 0.4s;
  line-height: 24px;
  max-width: 500px;
  min-width: 400px;
  &:hover {
    /* border:#1976d2 1px solid; */
    transform: scale(1.005);
  }
  ${mqMax("lg")} {
    max-width: 100%;
    min-width: 240px;
  }
`;
const Blocks = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  column-gap: 50px;
  ${mqMax("lg")} {
    row-gap: 30px;
    flex-direction: column;
  }
`;
const Wrapper = styled.div`
  padding-top: 50px;
`;
const Container = styled.div`
  padding: 50px 200px;
  max-width: ${containers.main};
  margin: 0 auto;
  text-align: center;
  ${mqMax("xxl")} {
    padding: 0px 30px;
  }
`;
