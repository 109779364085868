import React from "react";
import { theme } from "src/styles/theme";
import styled from "styled-components";

import danger from "src/shared/png/ourAdvantages/dangerGoods.jpg";
import lock from "src/shared/png/ourAdvantages/free-icon-privacy-policy-2260822.png";
import guard from "src/shared/png/ourAdvantages/guard.png";
import loyer from "src/shared/png/ourAdvantages/loyer.jpg";
export const OurAdvantages = () => {
  const array = [
    {
      icon: danger,
    },
    {
      icon: lock,
    },
    {
      icon: guard,
    },
  ];
  return (
    <Wrapper id="ourAd">
      <Container>
        <h1>Наши преимущества</h1>
        <Images>
          {array.map((item) => (
            <img src={item.icon} alt="" width={140} height={140} />
          ))}
          <img src={loyer} alt="" width={180} height={180} />
        </Images>
        <Blocks>
          <Block>
            <div className="">
              {/* <DeliveryDiningIcon
                sx={{
                  width: "100px",
                  height: "100px",
                }}
              /> */}
              <h4>МЫ ПРИНИМАЕМ и ДОСТАВЛЯЕМ</h4>
              <ul>
                <li>• все виды корреспонденции, включая ценные, секретные</li>
                <li>• драгоценные, опасные и специальные грузы</li>
                <li>• все областные, районные центры и города Республики.</li>
                <li>
                  • Доставка по Российской Федерации, а также Республике
                  Беларусь, Республике Казахстан, Республике Таджикистан,
                  Республике Узбекистан, Азербайджанской Республике и Республике
                  Армения.
                </li>
              </ul>
            </div>
          </Block>
          {/*  */}

          <Block>
            {/* <Inventory2Icon
              sx={{
                width: "100px",
                height: "100px",
              }}
            /> */}
            <h4>МЫ ОБЕСПЕЧИВАЕМ</h4>
            <ul>
              <li>• гарантия сохранности и максимальной безопасности,</li>
              <li>• строгая конфиденциальность и целостность отправлений,</li>
              <li>• своевременная доставка (выбор времени, сроков, места).</li>
              <li>• использование бронированных автомобилей</li>
              <li>
                • сопровождение сотрудниками, вооруженными служебным оружием.
              </li>
            </ul>
          </Block>
        </Blocks>
      </Container>
    </Wrapper>
  );
};

const { containers, mqMax } = theme;

const Images = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  row-gap: 20px;
  flex-wrap: wrap;
  img {
    object-fit: contain;
  }
  ${mqMax("lg")} {
  }
`;
const Block = styled.div`
  padding: 10px 20px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  box-shadow: 3px 2px 5px gray;
  transition-duration: 0.4s;
  line-height: 24px;
  max-width: 500px;
  min-width: 400px;
  &:hover {
    /* border:#1976d2 1px solid; */
    transform: scale(1.005);
  }
  ${mqMax("lg")} {
    max-width: 100%;
    min-width: 240px;
  }
`;
const Blocks = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  column-gap: 50px;
  ${mqMax("lg")} {
    row-gap: 30px;
    flex-direction: column;
  }
`;
const Wrapper = styled.div`
  padding-top: 50px;
`;
const Container = styled.div`
  padding: 50px 200px;
  max-width: ${containers.main};
  margin: 0 auto;
  text-align: center;
  ${mqMax("xxl")} {
    padding: 0px 30px;
  }
`;
