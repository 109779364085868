import React, { useState } from "react";
import { Tabs, Tab, Box } from "@mui/material";
import { ClientAuthComponent } from "src/widgets/ClientAuthComponent/ClientAuthComponent";
import { DispatcherAuthComponent } from "src/widgets/DispatcherAuthComponent/DispatcherAuthComponent";

export const ClientAuth = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event: any, newValue: any) => {
    setSelectedTab(newValue);
  };
  return (
    <div>
      <Tabs value={selectedTab} onChange={handleTabChange} centered>
        <Tab label="БУДЬ КУРЬЕРОМ" />
        <Tab label="БУДЬ ДИСПЕТЧЕРОМ" />
      </Tabs>
      <TabPanel value={selectedTab} index={0}>
        <ClientAuthComponent />
      </TabPanel>
      <TabPanel value={selectedTab} index={1}>
      <DispatcherAuthComponent/>
      </TabPanel>
    </div>
  );
};

function TabPanel(props: any) {
  const { children, value, index } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}
