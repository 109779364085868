
import React, { useState } from "react";
import {
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

export const Sender = (props:any) => {
  const [open, setOpen] = useState(false);
  const [senderData, setSenderData] = useState({
    who: "",
    locality: "",
    address: "",
    phoneNumber: "",
    message: "",
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSaveSenderData = () => {
    // Call the parent component's callback function to update the sender field
    props.onSaveSenderData(senderData);

    // Close the dialog
    handleClose();
  };

  return (
    <div>
      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        Если вам не трудно, просим оставить данные отправителя{" "}
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>ОТПРАВИТЕЛЬ</DialogTitle>
        <DialogContent>
          <DialogContentText></DialogContentText>
          <TextField
            label="От кого(необязательно)"
            variant="outlined"
            fullWidth
            margin="normal"
            name="who"
            value={senderData.who}
            onChange={(e) => setSenderData({ ...senderData, who: e.target.value })}
          />
          <TextField
            label="Населенный пункт(необязательно)"
            variant="outlined"
            fullWidth
            margin="normal"
            name="locality"
            value={senderData.locality}
            onChange={(e) => setSenderData({ ...senderData, locality: e.target.value })}
          />
          <TextField
            label="Адрес(необязательно)"
            variant="outlined"
            fullWidth
            margin="normal"
            name="address"
            value={senderData.address}
            onChange={(e) => setSenderData({ ...senderData, address: e.target.value })}
          />
          <TextField
            label="Телефон(необязательно)"
            variant="outlined"
            fullWidth
            margin="normal"
            name="phoneNumber"
            value={senderData.phoneNumber}
            onChange={(e) => setSenderData({ ...senderData, phoneNumber: e.target.value })}
          />
          <TextField
            label="Дополнительная информация(необязательно)"
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            margin="normal"
            name="message"
            value={senderData.message}
            onChange={(e) => setSenderData({ ...senderData, message: e.target.value })}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Закрыть
          </Button>
          <Button onClick={handleSaveSenderData} color="primary">
            Сохранить
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
